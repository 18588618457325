import _index from "../get_day_of_year/index.js";
import _index2 from "../get_iso_week/index.js";
import _index3 from "../get_iso_year/index.js";
import _index4 from "../parse/index.js";
import _index5 from "../is_valid/index.js";
import _index6 from "../locale/en/index.js";
var exports = {};
var getDayOfYear = _index;
var getISOWeek = _index2;
var getISOYear = _index3;
var parse = _index4;
var isValid = _index5;
var enLocale = _index6;
/**
 * @category Common Helpers
 * @summary Format the date.
 *
 * @description
 * Return the formatted date string in the given format.
 *
 * Accepted tokens:
 * | Unit                    | Token | Result examples                  |
 * |-------------------------|-------|----------------------------------|
 * | Month                   | M     | 1, 2, ..., 12                    |
 * |                         | Mo    | 1st, 2nd, ..., 12th              |
 * |                         | MM    | 01, 02, ..., 12                  |
 * |                         | MMM   | Jan, Feb, ..., Dec               |
 * |                         | MMMM  | January, February, ..., December |
 * | Quarter                 | Q     | 1, 2, 3, 4                       |
 * |                         | Qo    | 1st, 2nd, 3rd, 4th               |
 * | Day of month            | D     | 1, 2, ..., 31                    |
 * |                         | Do    | 1st, 2nd, ..., 31st              |
 * |                         | DD    | 01, 02, ..., 31                  |
 * | Day of year             | DDD   | 1, 2, ..., 366                   |
 * |                         | DDDo  | 1st, 2nd, ..., 366th             |
 * |                         | DDDD  | 001, 002, ..., 366               |
 * | Day of week             | d     | 0, 1, ..., 6                     |
 * |                         | do    | 0th, 1st, ..., 6th               |
 * |                         | dd    | Su, Mo, ..., Sa                  |
 * |                         | ddd   | Sun, Mon, ..., Sat               |
 * |                         | dddd  | Sunday, Monday, ..., Saturday    |
 * | Day of ISO week         | E     | 1, 2, ..., 7                     |
 * | ISO week                | W     | 1, 2, ..., 53                    |
 * |                         | Wo    | 1st, 2nd, ..., 53rd              |
 * |                         | WW    | 01, 02, ..., 53                  |
 * | Year                    | YY    | 00, 01, ..., 99                  |
 * |                         | YYYY  | 1900, 1901, ..., 2099            |
 * | ISO week-numbering year | GG    | 00, 01, ..., 99                  |
 * |                         | GGGG  | 1900, 1901, ..., 2099            |
 * | AM/PM                   | A     | AM, PM                           |
 * |                         | a     | am, pm                           |
 * |                         | aa    | a.m., p.m.                       |
 * | Hour                    | H     | 0, 1, ... 23                     |
 * |                         | HH    | 00, 01, ... 23                   |
 * |                         | h     | 1, 2, ..., 12                    |
 * |                         | hh    | 01, 02, ..., 12                  |
 * | Minute                  | m     | 0, 1, ..., 59                    |
 * |                         | mm    | 00, 01, ..., 59                  |
 * | Second                  | s     | 0, 1, ..., 59                    |
 * |                         | ss    | 00, 01, ..., 59                  |
 * | 1/10 of second          | S     | 0, 1, ..., 9                     |
 * | 1/100 of second         | SS    | 00, 01, ..., 99                  |
 * | Millisecond             | SSS   | 000, 001, ..., 999               |
 * | Timezone                | Z     | -01:00, +00:00, ... +12:00       |
 * |                         | ZZ    | -0100, +0000, ..., +1200         |
 * | Seconds timestamp       | X     | 512969520                        |
 * | Milliseconds timestamp  | x     | 512969520900                     |
 *
 * The characters wrapped in square brackets are escaped.
 *
 * The result may vary by locale.
 *
 * @param {Date|String|Number} date - the original date
 * @param {String} [format='YYYY-MM-DDTHH:mm:ss.SSSZ'] - the string of tokens
 * @param {Object} [options] - the object with options
 * @param {Object} [options.locale=enLocale] - the locale object
 * @returns {String} the formatted date string
 *
 * @example
 * // Represent 11 February 2014 in middle-endian format:
 * var result = format(
 *   new Date(2014, 1, 11),
 *   'MM/DD/YYYY'
 * )
 * //=> '02/11/2014'
 *
 * @example
 * // Represent 2 July 2014 in Esperanto:
 * var eoLocale = require('date-fns/locale/eo')
 * var result = format(
 *   new Date(2014, 6, 2),
 *   'Do [de] MMMM YYYY',
 *   {locale: eoLocale}
 * )
 * //=> '2-a de julio 2014'
 */

function format(dirtyDate, dirtyFormatStr, dirtyOptions) {
  var formatStr = dirtyFormatStr ? String(dirtyFormatStr) : "YYYY-MM-DDTHH:mm:ss.SSSZ";
  var options = dirtyOptions || {};
  var locale = options.locale;
  var localeFormatters = enLocale.format.formatters;
  var formattingTokensRegExp = enLocale.format.formattingTokensRegExp;

  if (locale && locale.format && locale.format.formatters) {
    localeFormatters = locale.format.formatters;

    if (locale.format.formattingTokensRegExp) {
      formattingTokensRegExp = locale.format.formattingTokensRegExp;
    }
  }

  var date = parse(dirtyDate);

  if (!isValid(date)) {
    return "Invalid Date";
  }

  var formatFn = buildFormatFn(formatStr, localeFormatters, formattingTokensRegExp);
  return formatFn(date);
}

var formatters = {
  // Month: 1, 2, ..., 12
  "M": function (date) {
    return date.getMonth() + 1;
  },
  // Month: 01, 02, ..., 12
  "MM": function (date) {
    return addLeadingZeros(date.getMonth() + 1, 2);
  },
  // Quarter: 1, 2, 3, 4
  "Q": function (date) {
    return Math.ceil((date.getMonth() + 1) / 3);
  },
  // Day of month: 1, 2, ..., 31
  "D": function (date) {
    return date.getDate();
  },
  // Day of month: 01, 02, ..., 31
  "DD": function (date) {
    return addLeadingZeros(date.getDate(), 2);
  },
  // Day of year: 1, 2, ..., 366
  "DDD": function (date) {
    return getDayOfYear(date);
  },
  // Day of year: 001, 002, ..., 366
  "DDDD": function (date) {
    return addLeadingZeros(getDayOfYear(date), 3);
  },
  // Day of week: 0, 1, ..., 6
  "d": function (date) {
    return date.getDay();
  },
  // Day of ISO week: 1, 2, ..., 7
  "E": function (date) {
    return date.getDay() || 7;
  },
  // ISO week: 1, 2, ..., 53
  "W": function (date) {
    return getISOWeek(date);
  },
  // ISO week: 01, 02, ..., 53
  "WW": function (date) {
    return addLeadingZeros(getISOWeek(date), 2);
  },
  // Year: 00, 01, ..., 99
  "YY": function (date) {
    return addLeadingZeros(date.getFullYear(), 4).substr(2);
  },
  // Year: 1900, 1901, ..., 2099
  "YYYY": function (date) {
    return addLeadingZeros(date.getFullYear(), 4);
  },
  // ISO week-numbering year: 00, 01, ..., 99
  "GG": function (date) {
    return String(getISOYear(date)).substr(2);
  },
  // ISO week-numbering year: 1900, 1901, ..., 2099
  "GGGG": function (date) {
    return getISOYear(date);
  },
  // Hour: 0, 1, ... 23
  "H": function (date) {
    return date.getHours();
  },
  // Hour: 00, 01, ..., 23
  "HH": function (date) {
    return addLeadingZeros(date.getHours(), 2);
  },
  // Hour: 1, 2, ..., 12
  "h": function (date) {
    var hours = date.getHours();

    if (hours === 0) {
      return 12;
    } else if (hours > 12) {
      return hours % 12;
    } else {
      return hours;
    }
  },
  // Hour: 01, 02, ..., 12
  "hh": function (date) {
    return addLeadingZeros(formatters["h"](date), 2);
  },
  // Minute: 0, 1, ..., 59
  "m": function (date) {
    return date.getMinutes();
  },
  // Minute: 00, 01, ..., 59
  "mm": function (date) {
    return addLeadingZeros(date.getMinutes(), 2);
  },
  // Second: 0, 1, ..., 59
  "s": function (date) {
    return date.getSeconds();
  },
  // Second: 00, 01, ..., 59
  "ss": function (date) {
    return addLeadingZeros(date.getSeconds(), 2);
  },
  // 1/10 of second: 0, 1, ..., 9
  "S": function (date) {
    return Math.floor(date.getMilliseconds() / 100);
  },
  // 1/100 of second: 00, 01, ..., 99
  "SS": function (date) {
    return addLeadingZeros(Math.floor(date.getMilliseconds() / 10), 2);
  },
  // Millisecond: 000, 001, ..., 999
  "SSS": function (date) {
    return addLeadingZeros(date.getMilliseconds(), 3);
  },
  // Timezone: -01:00, +00:00, ... +12:00
  "Z": function (date) {
    return formatTimezone(date.getTimezoneOffset(), ":");
  },
  // Timezone: -0100, +0000, ... +1200
  "ZZ": function (date) {
    return formatTimezone(date.getTimezoneOffset());
  },
  // Seconds timestamp: 512969520
  "X": function (date) {
    return Math.floor(date.getTime() / 1000);
  },
  // Milliseconds timestamp: 512969520900
  "x": function (date) {
    return date.getTime();
  }
};

function buildFormatFn(formatStr, localeFormatters, formattingTokensRegExp) {
  var array = formatStr.match(formattingTokensRegExp);
  var length = array.length;
  var i;
  var formatter;

  for (i = 0; i < length; i++) {
    formatter = localeFormatters[array[i]] || formatters[array[i]];

    if (formatter) {
      array[i] = formatter;
    } else {
      array[i] = removeFormattingTokens(array[i]);
    }
  }

  return function (date) {
    var output = "";

    for (var i = 0; i < length; i++) {
      if (array[i] instanceof Function) {
        output += array[i](date, formatters);
      } else {
        output += array[i];
      }
    }

    return output;
  };
}

function removeFormattingTokens(input) {
  if (input.match(/\[[\s\S]/)) {
    return input.replace(/^\[|]$/g, "");
  }

  return input.replace(/\\/g, "");
}

function formatTimezone(offset, delimeter) {
  delimeter = delimeter || "";
  var sign = offset > 0 ? "-" : "+";
  var absOffset = Math.abs(offset);
  var hours = Math.floor(absOffset / 60);
  var minutes = absOffset % 60;
  return sign + addLeadingZeros(hours, 2) + delimeter + addLeadingZeros(minutes, 2);
}

function addLeadingZeros(number, targetLength) {
  var output = Math.abs(number).toString();

  while (output.length < targetLength) {
    output = "0" + output;
  }

  return output;
}

exports = format;
export default exports;